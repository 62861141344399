import React from 'react';
import { Button, Flex, Input, Modal } from 'antd';
import { jwtDecode } from "jwt-decode";
import { openNotification } from "../functions";
import { withRouter } from '../components/withRouter';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      login: "",
      password: ""
    };

    this.customNavFunction=this.customNavFunction.bind(this);
  }

  customNavFunction(){
    let tok = jwtDecode(localStorage.getItem("token"));
    if(tok.role == 1) {
      this.props.navigate('/clients');
    }

    if(tok.role == 2) {
      this.props.navigate('/me');
    }

    if(tok.role == 3) {
      this.props.navigate('/teacher');
    }
  }

  post() {
    fetch(`https://student-control.nti-center.ru/api/login`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        login: this.state.login,
        password: this.state.password
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          if (response.auth) {
            localStorage.setItem('token', response.auth);
            this.customNavFunction();
          }
        }
      })
  }

  onChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  }

  render() {
    return (
      <div className="Mp_bg">
        <div className="mainCSWrap">
          <div className='topContent'>
            <div className="titles">
              <div className="title1">STUDENT-CONTROL V2.0</div>
              <div className="title2">Твой путь к новой профессии</div>
              <div className="title3" style={{ color: '#cdcdcd' }}>Проект ЦОК НТИ</div>
            </div>
            <div className="topBtnPlace">
              <div className="topBtn" style={{ cursor: "pointer" }} onClick={() => { this.setState({isModalOpen: true}); }}>Войти</div>
            </div>
          </div>
          <div className='centerContent'>
            <div className="content">
              <img alt="bigLogo" src="./images/bigLogo.webp" />
            </div>
          </div>
          <div className='bottomContent'>
            <div className="bottomBtnPlace">
              <a className="bottomBtn" target='_blank' href="https://nti-center.ru" style={{textDecoration: 'unset'}}>Перейти на сайт</a>
            </div>
            <div className="soc_icons">
              <a href="https://vk.com/nti_center" target='_blank' className="soc_icon vk">
                <img alt="vk" src="./images/vk.png" /> Вконтакте
              </a>
              <a href="https://t.me/nti_center" target='_blank' className="soc_icon telegram">
                <img alt="telegram" src="./images/telegram.png" /> Telegram
              </a>
            </div>
          </div>
        </div>

        <Modal
          className='login_form'
          open={this.state.isModalOpen}
          width={520}
          onCancel={() => { this.setState({isModalOpen: false}); }}
          footer={[
            <Flex
              vertical
              key="btnsKeys"
              gap="small"
              style={{
                width: '100%',
              }}
            >
              <Button type='primary' onClick={() => { this.post(); }}>Войти</Button>
              <Button onClick={() => { this.setState({isModalOpen: false}); }}>Отмена</Button>
            </Flex>
          ]}
        >
          <img style={{ width: '71px' }} alt="bigLogo" src="./images/bigLogo.webp" />
          <div className='company_name'>ЦОК НТИ</div>
          <Input placeholder='Логин' variant='filled' name="login" onChange={this.onChange.bind(this)} />
          <Input placeholder='Пароль' variant='filled' name="password" onChange={this.onChange.bind(this)} />
          {/* <div style={{ margin: '20px 0', textDecoration: 'underline' }}>Забыли логин или пароль?</div> */}
        </Modal>
      </div>
    )
  }

} 

export default withRouter(Login);