import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import menu_logo from '../images/menu_logo.png';
import { jwtDecode } from 'jwt-decode';


export const NavBar = () => {
  let tok = jwtDecode(localStorage.getItem("token"));

  //Меню админа
  if (tok.role == 1) {
    return (
      <div className='mainMenu'>
        <img src={menu_logo} />
        <Link to="/users">Пользователи</Link>
        <Link to="/student-control-courses">Курсы</Link>
        <Link to="/clients">Слушатели</Link>
        <Link to="/mediafiles">Медиафайлы</Link>
        <Link to="/">Выход</Link>
      </div>
    )
  }

  //Меню слушателя
  if (tok.role == 2) {
    return (
      <div className='mainMenu'>
        <img src={menu_logo} />
        <Link to="/me">Профиль и курсы</Link>
        {/* <Link to="/me/courses">Мои курсы</Link> */}
        <Link to="/">Выход</Link>
      </div>
    )
  }

  //Меню преподавателя
  if (tok.role == 3) {
    return (
      <div className='mainMenu'>
        <img src={menu_logo} />
        <Link to="/teacher">Мой профиль и курсы</Link>
        <Link to="/">Выход</Link>
      </div>
    )
  }

};