import React from "react";
import {
  DeleteOutlined,
  CopyOutlined,
  InboxOutlined
} from '@ant-design/icons';
import { Table, Button, Modal, Input, Popconfirm, Select, Card, Space, Empty, Tooltip, Upload, message } from 'antd';
// import config from '../config.json';
import { openNotification } from "../../functions";
import axios from 'axios';
import { NavBar } from "../../components/navBar";

export default class Mediafiles extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      mediafiles: [],
      uploadModal: false
    };
  }

  getMediafiles() {
    fetch(`https://student-control.nti-center.ru/api/mediafiles`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({ mediafiles: response.data })
        }
      })
  }

  deleteFile(id) {
    fetch(`https://student-control.nti-center.ru/api/mediafiles/${id}`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'DELETE',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.getMediafiles();
        }
      })
  }


  render() {

    const customRequest = async (options) => {
      const { onSuccess, onError, file, onProgress } = options;
      const data = new FormData();
      data.append('file', options.file);

      const config = {
        headers: {
          'content-type': 'multipart/form-data; charset=utf-8',
          'charset': 'utf-8',
          'Authorization': 'CokCRM ' + localStorage.getItem('token')
        },
      };

      axios
        .post(`https://student-control.nti-center.ru/api/mediafiles`, data, config)
        .then((res) => {
          console.log(res);
          if (res.data.error) {
            openNotification('Ошибка', res.error, 1, 4.5);
          } else {
            onSuccess("Ok");
            this.getMediafiles();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const { Dragger } = Upload;
    return (
      <div>
        <NavBar />
        <div className="pageContent">
          <div className="page_name">Медиафайлы</div>
          <Space size={10} style={{ marginBottom: 20 }}>
            <Button onClick={() => { this.setState({ uploadModal: true }) }} type={'primary'}>Загрузить файл</Button>
          </Space>

          {
            this.state.mediafiles.length == 0 ?
              <Empty description={"Ничего не найдено"} />
              :
              <div className="files_cards">
                {
                  this.state.mediafiles.map((file, index) => {
                    return (
                      <Card key={index}
                        style={{
                          width: 300,
                        }}
                        actions={[
                          <Tooltip onClick={async () => {
                            await navigator.clipboard.writeText(`https://student-control.nti-center.ru/${file.url}`);
                            message.success('Ссылка на файл скопирована');
                          }} title="Копировать ссылку"><CopyOutlined /></Tooltip>,
                          <Popconfirm
                            title="Удаление файла"
                            description="Вы уверены, что хотите удалить?"
                            onConfirm={() => { this.deleteFile(file.id) }}
                            onCancel={() => { }}
                            okText="Удалить"
                            cancelText="Отмена"
                          >
                            <Tooltip title="Удалить файл"><DeleteOutlined /></Tooltip>
                          </Popconfirm>
                        ]}
                        cover={
                          <img src={`https://student-control.nti-center.ru/${file.url}`} />
                        }
                      >
                        {file.name}
                      </Card>
                    )
                  })
                }
              </div>
          }

          <Modal title="Загрузка файла" open={this.state.uploadModal} onOk={() => { }} onCancel={() => { this.setState({ uploadModal: false }) }}>
            <Dragger
              name={'file'}
              multiple={true}
              customRequest={customRequest}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Щелкните или перетащите файл в эту область для загрузки</p>
              <p className="ant-upload-hint">
                Поддерживается однократная или массовая загрузка. Строго запрещено загружать данные о компании или другиезапрещенные файлы.
              </p>
            </Dragger>
          </Modal>

        </div>
      </div>
    );
  }

  componentDidMount() {
    this.getMediafiles();
  }

}