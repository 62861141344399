import React from "react";
// import config from '../config.json';
import { openNotification } from "../../functions";
import { Card } from 'antd';
import { Link } from "react-router-dom";
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import { NavBar } from "../../components/navBar";


export default class MyCourses extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeCourses: [],
      courses: []
    };
  }

  getCourses() {
    fetch(`https://student-control.nti-center.ru/api/me/mycourses`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({
            activeCourses: response.data
          });
        }
      })
  }

  getAllCourses() {
    fetch(`https://student-control.nti-center.ru/api/student-control`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          console.log({ "ALL": response.data });
          this.setState({
            courses: response.data
          });
        }
      })
  }

  render() {
    return (
      <div>
        <NavBar />
        <div className="pageContent">
          <div className="page_name">Доступные курсы</div>
          {this.state.activeCourses.length == 0 ?
            "Вы не зачислены ни на один курс"
            :
            <div className="courses">
              {this.state.activeCourses.map((course, index) => {
                return (
                  <Card>
                    <div className="course_img" style={{ background: "url(/" + course.sc_image + ")" }}></div>
                    <div className="course_name">{course.sc_name}</div>
                    <div className="course_desc" dangerouslySetInnerHTML={{ __html: stateToHTML(convertFromRaw(JSON.parse(course.sc_desc))).substring(0, 90) + "..." }}></div>
                    {
                      course.scg_status == 1 ?
                        <Link className="course_link" to={'/me/courses/' + course.sc_id}>Подробнее</Link>
                        :
                        <div>
                          Старт курса: {course.scg_date_start}
                          <br />
                          Окончание: {course.scg_date_end}
                        </div>
                    }

                  </Card>
                )
              })}
            </div>
          }
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.getCourses();
  }
}