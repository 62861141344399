import React, { Fragment } from "react";
// import config from '../config.json';
import { openNotification } from "../../functions";
import { Drawer, Space, Button, Modal, Result, Progress, Tabs, Card, Tooltip, Input, Spin, Collapse, Table } from 'antd';
import {
  EyeOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';

import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import { NavBar } from "../../components/navBar";
import { withRouter } from '../../components/withRouter';

import moment from 'moment';
import TextArea from "antd/es/input/TextArea";

class TeacherCourse extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      resultsList: [],
      course: [],
      course_id: "",
      homeWorkModal: false,
      homeWork: [],
      homeWorkQuestions: [],
      homeWorkType: "",
      homeWorkName: "",
      homeWorkId: "",
      homeWorkNeedAnswer: 0,
      homeWorkAnswer: [],
      homeWorkReturn: false
    };
  }

  getCourse() {
    fetch(`https://student-control.nti-center.ru/api/teacher/courses/` + window.location.href.split('/course/')[1], {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
          this.props.navigate('/teacher/');
        } else {
          this.setState({ course: response.data[0] });
          this.getResults(response.data[0].sc_id)
        }
      })
  }

  getResults(id) {
    fetch(`https://student-control.nti-center.ru/api/teacher/courses/` + id + "/results", {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {

        } else {
          let arr = [];

          response.data.forEach((element, index) => {
            arr.push({
              fio: element.client_surname + " " + element.client_name + " " + element.client_patronymic,
              date: element.scr_date,
              name: element.scl_name + " (" + element.scl_type + ")",
              events: <Tooltip title="Смотреть работу"><EyeOutlined onClick={() => { this.getResult(element.scr_id) }} /></Tooltip>
            })
          });

          this.setState({ resultsList: arr });
        }
      })
  }

  getResult(id) {
    fetch(`https://student-control.nti-center.ru/api/teacher/courses/` + this.state.course.sc_id + "/results/" + id, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({
            homeWorkQuestions: stateToHTML(convertFromRaw(JSON.parse(response.data[0].scl_body))) && stateToHTML(convertFromRaw(JSON.parse(response.data[0].scl_body))).replace(/href/g, `target='_blank' href`),
            homeWorkType: response.data[0].scl_type,
            homeWorkName: response.data[0].scl_name,
            homeWorkId: response.data[0].scr_id,
            homeWorkComment: response.data[0].sc_comment,
            homeWorkModal: true,
            homeWorkContent: response.data[0].sc_content,
            homeWork: response.data[0]
          })
        }
      })
  }

  sendResult(btn) {
    fetch(`https://student-control.nti-center.ru/api/teacher/courses/` + this.state.course.sc_id + "/results/" + this.state.homeWorkId + "/send", {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        comment: this.state.homeWorkComment,
        checked: btn
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', response.data, 3, 4.5);
          this.setState({
            homeWorkQuestions: "",
            homeWorkType: "",
            homeWorkName: "",
            homeWorkId: "",
            homeWorkComment: "",
            homeWorkModal: false,
            homeWorkContent: "",
            homeWork: ""
          });
          this.getCourse();
        }
      })
  }



  ChangeInputValue = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  }

  render() {
    const columns = [
      {
        title: 'ФИО',
        dataIndex: 'fio',
        key: 'fio'
      },
      {
        title: 'Дата сдачи задания',
        dataIndex: 'date',
        key: 'date',
        sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
        render: (text, record) => {
          return (
            moment(text).format('DD-MM-YYYY')
          )
        }
      },
      {
        title: 'Название задания',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Действия',
        dataIndex: 'events',
        key: 'events',
      }
    ];
    return (
      <div>
        <NavBar />
        <div className="pageContent">
          <div className="big_course_name">Курс: {this.state.course.sc_name}</div>
          <h3 style={{ marginTop: 30 }}>Задания на проверку</h3>

          <Table columns={columns} dataSource={this.state.resultsList} />
        </div>
        <Drawer
          width={window.innerWidth}
          title={this.state.homeWork.client_surname + " " + this.state.homeWork.client_name + " " + this.state.homeWork.client_patronymic}
          placement="right"
          onClose={() => { this.setState({ homeWorkModal: false }) }}
          open={this.state.homeWorkModal}
          extra={
            <Space>
              <Button onClick={() => { this.setState({ homeWorkModal: false }); }}>Отмена</Button>
            </Space>
          }
        >
          <h3>Задание:</h3>
          <div dangerouslySetInnerHTML={{ __html: this.state.homeWorkQuestions }}></div>

          <h3 style={{ marginTop: 30 }}>Ответ слушателя:</h3>
          <div>{this.state.homeWorkContent}</div>

          <h3 style={{ marginTop: 30 }}>Ваш комментарий:<Tooltip title="В случае отправки на дорабоку вы можете оставить комментарий, этот текст будет виден слушателю во время доработки домашнего задания"><InfoCircleOutlined style={{ cursor: 'pointer', marginLeft: '10px' }} /></Tooltip></h3>
          <TextArea rows={4} name="homeWorkComment" onChange={this.ChangeInputValue.bind(this)} value={this.state.homeWorkComment} />

          <Space style={{ marginTop: 30 }}>
            <Button onClick={() => { this.sendResult(0) }} type='primary'>Принять работу</Button>
            <Button onClick={() => { this.sendResult(2) }}>Отправить на доработку</Button>
          </Space>
        </Drawer>
      </div>
    );
  }

  componentDidMount() {
    this.getCourse();
  }
}

export default withRouter(TeacherCourse);