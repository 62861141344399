import React from "react";
import {
  EyeOutlined,
  DeleteOutlined,
  MailOutlined,
  PhoneOutlined
} from '@ant-design/icons';
import { Table, Button, Input, Popconfirm, Space, Drawer, Col, Divider, Row, DatePicker, ConfigProvider, Select, Alert, Modal, Tooltip, AutoComplete } from 'antd';
import { SearchOutlined, RedoOutlined, LinkOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

import { openNotification } from "../../functions";
import Highlighter from 'react-highlight-words';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import locale from 'antd/locale/ru_RU';
import axios from "axios";
import * as xlsx from 'xlsx';
import { NavBar } from "../../components/navBar";

const { Option } = Select;

export default class Newclients extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeBlock: 0,
      modal: false,
      loadusers: false,
      statuses: "",
      clientPassEdit: false,
      parsingFieldsModal: false,
      loadInput: "",
      loadInputName: "Нажмите сюда и выберите файл для загрузки",
      uploadFileJson: [
        {
          "Номер заявки": 123,
        },
      ],
      uploadFileJsonFields: [],
      uploadClientName: null,
      uploadClientSurname: null,
      uploadClientPatronymic: null,
      uploadClientMail: null,
      uploadClientWorkMail: null,
      uploadClientAdditionalMail: null,
      uploadClientPhone: null,
      uploadClientWorkPhone: null,
      uploadClientAdditionalPhone: null,
      uploadClientCity: null,
      uploadClientCity2: null,
      uploadClientBirthday: null,
      uploadClientDocDate: null,
      uploadClientDocNumber: null,
      uploadClientDocSeries: null,
      uploadClientFioDiplom: null,
      uploadClientLevelDiplom: null,
      uploadClientNumberDiplom: null,
      uploadClientSeriesDiplom: null,
      uploadClientPol: null,
      uploadClientRegistrationNumber: null,
      uploadClientSnils: null,
      uploadClientInstitution: null,
      uploadClientCourseId: null,
      uploadClientGroupId: null,
      uploadClientProjectId: null,
      uploadClientPrice: null,
      uploadClientCategory: null,
      uploadClientStatus: null,
      uploadClientFedLink: null,
      uploadClientRegion: null,
      uploadClientManager: null,
      uploadClientNumberTransferOrder: null,
      uploadClientDateTransferOrder: null,
      uploadClientNumberWithdrawalOrder: null,
      uploadClientDateWithdrawalOrder: null,
      clients: [],
      courses: [],
      groups: [],
      projects: [],
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      newClientName: "",
      newClientSurname: "",
      newClientPatronymic: "",
      newClientMail: "",
      newClientWorkMail: "",
      newClientAdditionalMail: "",
      newClientPhone: "",
      newClientWorkPhone: "",
      newClientAdditionalPhone: "",
      newClientCity: "",
      newClientCity2: "",
      newClientRegion: "",
      newClientBirthday: "00.00.0000",
      newClientDocDate: "",
      newClientDocNumber: "",
      newClientDocSeries: "",
      newClientFioDiplom: "",
      newClientLevelDiplom: "",
      newClientNumberDiplom: "",
      newClientSeriesDiplom: "",
      newClientPol: "",
      newClientRegistrationNumber: "",
      newClientSnils: "",
      newClientInstitution: "",
      newClientCourseId: "",
      newClientGroupId: "",
      newClientProjectId: "",
      newClientPrice: "",
      newClientCategory: "",
      newClientStatus: "",
      client_window: false,
      thisNewClient: false,
      inputChanged: false,
      newClientId: "",
      newClientLogin: "",
      newClientPassword: "",
      newClientPlatformId: "",
      newClientFedLink: "",
      newClientManager: "",
      newClientNumberTransferOrder: "",
      newClientDateTransferOrder: "",
      newClientNumberWithdrawalOrder: "",
      newClientDateWithdrawalOrder: "",
      platforms: "",
      users: []
    };
  }

  ChangeInputValue = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [name]: value, inputChanged: true });
  }

  clearNewClientData() {
    this.setState({
      newClientId: "",
      newClientName: "",
      newClientSurname: "",
      newClientPatronymic: "",
      newClientMail: "",
      newClientWorkMail: "",
      newClientAdditionalMail: "",
      newClientPhone: "",
      newClientWorkPhone: "",
      newClientAdditionalPhone: "",
      newClientCity: "",
      newClientCity2: "",
      newClientRegion: "",
      newClientBirthday: "",
      newClientDocDate: "",
      newClientDocNumber: "",
      newClientDocSeries: "",
      newClientFioDiplom: "",
      newClientLevelDiplom: "",
      newClientNumberDiplom: "",
      newClientSeriesDiplom: "",
      newClientPol: "",
      newClientRegistrationNumber: "",
      newClientSnils: "",
      newClientInstitution: "",
      newClientCourseId: "",
      newClientGroupId: "",
      newClientProjectId: "",
      newClientPrice: "",
      newClientCategory: "",
      newClientStatus: "",
      newClientLogin: "",
      newClientPassword: "",
      newClientPlatformId: "",
      newClientFedLink: "",
      newClientManager: "",
      newClientNumberTransferOrder: "",
      newClientDateTransferOrder: "",
      newClientNumberWithdrawalOrder: "",
      newClientDateWithdrawalOrder: "",
    })
  }
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({ searchText: selectedKeys[0], searchedColumn: dataIndex });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  getClientInfo(id) {
    fetch(`https://student-control.nti-center.ru/api/clients/` + id + '/new', {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({
            newClientBirthday: response.data[0].birthday ? response.data[0].birthday : "00.00.0000",
            newClientFioDiplom: response.data[0].fio_diplom,
            newClientSeriesDiplom: response.data[0].series_diplom,
            newClientNumberDiplom: response.data[0].number_diplom,
            newClientLevelDiplom: response.data[0].level_diplom,
            newClientDocSeries: response.data[0].doc_series,
            newClientDocNumber: response.data[0].doc_number,
            newClientDocDate: response.data[0].doc_date,
            newClientRegistrationNumber: response.data[0].registration_number,
            newClientPol: response.data[0].pol,
            newClientSnils: response.data[0].snils,
            newClientCourseId: response.data[0].client_courses,
            newClientGroupId: response.data[0].client_group,
            newClientProjectId: response.data[0].client_project,
            newClientPrice: response.data[0].price,
            newClientCategory: response.data[0].category,
            newClientInstitution: response.data[0].institution,
            newClientStatus: response.data[0].client_status,
            newClientLogin: response.data[0].client_login,
            newClientPlatformId: response.data[0].platform_id,
            newClientFedLink: response.data[0].fed_link,
            newClientManager: response.data[0].manager_id,
            newClientNumberTransferOrder: response.data[0].number_transfer_order,
            newClientDateTransferOrder: response.data[0].date_transfer_order,
            newClientNumberWithdrawalOrder: response.data[0].number_withdrawal_order,
            newClientDateWithdrawalOrder: response.data[0].date_withdrawal_order,
          });
        }
      })
  }

  closeDrawer() {
    this.setState({ client_window: false, thisNewClient: false, inputChanged: false });
  }

  getClients() {
    fetch(`https://student-control.nti-center.ru/api/clients/new`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          var arr = [];
          response.data.map((client) => {
            arr.push({
              key: client.client_id,
              client_name: client.client_name,
              client_surname: client.client_surname,
              client_patronymic: client.client_patronymic,
              client_contacts: <div>
                {client.client_mail ? <div><MailOutlined /> {client.client_mail}</div> : ""}
                {client.client_work_mail ? <div><MailOutlined /> {client.client_work_mail}</div> : ""}
                {client.client_additional_mail ? <div><MailOutlined /> {client.client_additional_mail}</div> : ""}
                {client.client_phone ? <div><PhoneOutlined /> {client.client_phone}</div> : ""}
                {client.client_work_phone ? <div><PhoneOutlined /> {client.client_work_phone}</div> : ""}
                {client.client_additional_phone ? <div><PhoneOutlined /> {client.client_additional_phone}</div> : ""}
              </div>,
              client_city: client.client_city,
              client_category: client.category,
              events: <div>
                {client.fed_link ? <a style={{ cursor: 'pointer', marginRight: '10px' }} href={client.fed_link} rel="noreferrer" target="_blank"><LinkOutlined /></a> : ""}

                <Tooltip title="Смотреть">
                  <EyeOutlined onClick={() => {
                    this.setState({ client_window: true, newClientId: client.client_id, newClientName: client.client_name, newClientSurname: client.client_surname, newClientPatronymic: client.client_patronymic, newClientMail: client.client_mail, newClientWorkMail: client.client_work_mail, newClientAdditionalMail: client.client_additional_mail, newClientCity: client.client_city, newClientCity2: client.client_city2, newClientRegion: client.client_region, newClientPhone: client.client_phone, newClientWorkPhone: client.client_work_phone, newClientAdditionalPhone: client.client_additional_phone });
                    this.getClientInfo(client.client_id);
                  }} style={{ cursor: 'pointer', marginRight: '10px' }} />
                </Tooltip>
                <Popconfirm
                  title={client.client_state == 1 ? "Вы уверены что хотите заблокировать клиента?" : "Вы уверены что хотите разблокировать клиента?"}
                  okText="Да"
                  onConfirm={() => { this.banClient(client.client_id, client.client_state == 1 ? 0 : 1); }}
                  cancelText="Нет"
                >
                  {
                    client.client_state == 1 ?
                      <Tooltip title="Клиент активен">
                        <CheckCircleTwoTone style={{ cursor: 'pointer', marginRight: '10px' }} twoToneColor="#52c41a" />
                      </Tooltip>
                      :
                      <Tooltip title="Клиент заблокирован">
                        <CloseCircleTwoTone style={{ cursor: 'pointer', marginRight: '10px' }} twoToneColor="#eb2f96" />
                      </Tooltip>
                  }
                </Popconfirm>
                {/* <Popconfirm
                  title="Вы уверены что хотите удалить клиента?"
                  okText="Да"
                  onConfirm={() => { this.deleteClient(client.client_id); }}
                  cancelText="Нет"
                >
                  <DeleteOutlined />
                </Popconfirm> */}
              </div>
            })
          })
          this.setState({ clients: arr });
        }
      })
  }

  banClient(id, state) {
    fetch(`https://student-control.nti-center.ru/api/clients/new/ban/${id}`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'PATCH',
      cache: 'no-cache',
      body: JSON.stringify({
        state: state
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.getClients();
        }
      });

  }

  getColumnSearchProps = (dataIndex, placeName) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input ref={this.state.searchInput} placeholder={`Поиск ${placeName}`} value={selectedKeys[0]} onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])} onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{}}>Поиск</Button>
          <Button onClick={() => clearFilters && this.handleReset(clearFilters)} size="small" >Сбросить</Button>
          <Button type="link" size="small" onClick={() => { close(); }}>Закрыть</Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.state.searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });


  patchClient() {
    fetch(`https://student-control.nti-center.ru/api/clients/new`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'PATCH',
      cache: 'no-cache',
      body: JSON.stringify({
        "client_id": this.state.newClientId,
        "client_name": this.state.newClientName,
        "client_surname": this.state.newClientSurname,
        "client_patronymic": this.state.newClientPatronymic,
        "client_mail": this.state.newClientMail,
        "client_work_mail": this.state.newClientWorkMail,
        "client_additional_mail": this.state.newClientAdditionalMail,
        "client_phone": this.state.newClientPhone,
        "client_work_phone": this.state.newClientWorkPhone,
        "client_additional_phone": this.state.newClientAdditionalPhone,
        "client_city": this.state.newClientCity,
        "client_city2": this.state.newClientCity2,
        "client_region": this.state.newClientRegion,
        "course_id": this.state.newClientCourseId,
        "group_id": this.state.newClientGroupId,
        "project_id": this.state.newClientProjectId,
        "client_status": this.state.newClientStatus,
        "snils": this.state.newClientSnils,
        "pol": this.state.newClientPol,
        "birthday": this.state.newClientBirthday,
        "fio_diplom": this.state.newClientFioDiplom,
        "series_diplom": this.state.newClientSeriesDiplom,
        "number_diplom": this.state.newClientNumberDiplom,
        "level_diplom": this.state.newClientLevelDiplom,
        "institution": this.state.newClientInstitution,
        "doc_series": this.state.newClientDocSeries,
        "doc_number": this.state.newClientDocNumber,
        "doc_date": this.state.newClientDocDate,
        "registration_number": this.state.newClientRegistrationNumber,
        "category": this.state.newClientCategory,
        "price": this.state.newClientPrice,
        "client_login": this.state.newClientLogin,
        "platform_id": this.state.newClientPlatformId,
        "fed_link": this.state.newClientFedLink,
        "manager_id": this.state.newClientManager,
        "number_transfer_order": this.state.newClientNumberTransferOrder,
        "date_transfer_order": this.state.newClientDateTransferOrder,
        "number_withdrawal_order": this.state.newClientNumberWithdrawalOrder,
        "date_withdrawal_order": this.state.newClientDateWithdrawalOrder,
      })
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', 'Клиент успешно изменён!', 3, 4.5);
          if (this.state.modal === true) {
            this.setState({ modal: false });
          }
          // this.closeDrawer();
          this.getClients();
          // this.getGroups();
        }
      });
  }

  editClientPassword() {
    if (this.state.newClientPassword === "") {
      openNotification('Ошибка', "Поле нового пароля пустое", 1, 4.5);
    } else {
      fetch(`https://student-control.nti-center.ru/api/clients/ep/new`, {
        headers: new Headers({
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'CokCRM ' + localStorage.getItem('token')
        }),
        method: 'PATCH',
        cache: 'no-cache',
        body: JSON.stringify({
          "client_id": this.state.newClientId,
          "client_password": this.state.newClientPassword
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.error) {
            openNotification('Ошибка', response.error, 1, 4.5);
          } else {
            this.setState({ clientPassEdit: false, newClientPassword: "" })
            openNotification('Успех', 'Пароль клиента был изменен', 3, 4.5);
          }
        })
    }

  }

  createClient() {

    fetch(`https://student-control.nti-center.ru/api/clients/new`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        "client_name": this.state.newClientName,
        "client_surname": this.state.newClientSurname,
        "client_patronymic": this.state.newClientPatronymic,
        "client_mail": this.state.newClientMail,
        "client_work_mail": this.state.newClientWorkMail,
        "client_additional_mail": this.state.newClientAdditionalMail,
        "client_phone": this.state.newClientPhone,
        "client_work_phone": this.state.newClientWorkPhone,
        "client_additional_phone": this.state.newClientAdditionalPhone,
        "client_city": this.state.newClientCity,
        "client_city2": this.state.newClientCity2,
        "client_region": this.state.newClientRegion,
        "course_id": this.state.newClientCourseId,
        "group_id": this.state.newClientGroupId,
        "project_id": this.state.newClientProjectId,
        "client_status": this.state.newClientStatus,
        "snils": this.state.newClientSnils,
        "pol": this.state.newClientPol,
        "birthday": this.state.newClientBirthday,
        "fio_diplom": this.state.newClientFioDiplom,
        "series_diplom": this.state.newClientSeriesDiplom,
        "number_diplom": this.state.newClientNumberDiplom,
        "level_diplom": this.state.newClientLevelDiplom,
        "institution": this.state.newClientInstitution,
        "doc_series": this.state.newClientDocSeries,
        "doc_number": this.state.newClientDocNumber,
        "doc_date": this.state.newClientDocDate,
        "registration_number": this.state.newClientRegistrationNumber,
        "category": this.state.newClientCategory,
        "price": this.state.newClientPrice,
        "client_password": this.state.newClientPassword,
        "platform_id": this.state.newClientPlatformId,
        "fed_link": this.state.newClientFedLink,
        "manager_id": this.state.newClientManager,
        "number_transfer_order": this.state.newClientNumberTransferOrder,
        "date_transfer_order": this.state.newClientDateTransferOrder,
        "number_withdrawal_order": this.state.newClientNumberWithdrawalOrder,
        "date_withdrawal_order": this.state.newClientDateWithdrawalOrder
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', 'Вы создали клиента!', 3, 4.5);
          if (this.state.modal === true) {
            this.setState({ modal: false, newClientPassword: "" });
          }
          this.getClients();
        }
      });
  }

  render() {
    const columns = [
      {
        title: 'Фамилия',
        dataIndex: 'client_surname',
        key: 'client_surname',
        ...this.getColumnSearchProps('client_surname', 'по фамилии'),
      },
      {
        title: 'Имя',
        dataIndex: 'client_name',
        key: 'client_name',
        ...this.getColumnSearchProps('client_name', 'по имени'),
      },
      {
        title: 'Отчество',
        dataIndex: 'client_patronymic',
        key: 'client_patronymic',
        ...this.getColumnSearchProps('client_patronymic', 'по отчество'),
      },
      {
        title: 'Контакты',
        dataIndex: 'client_contacts',
        key: 'client_contacts',
        // ...this.getColumnSearchProps('client_mail', 'по почте'),
      },
      {
        title: 'Категория',
        dataIndex: 'client_category',
        key: 'client_category',
        ...this.getColumnSearchProps('client_category', 'по категории'),
      },
      {
        title: 'Фактический адрес',
        dataIndex: 'client_city',
        key: 'client_city',
        ...this.getColumnSearchProps('client_city', 'по Фактический адресу'),
      },
      {
        title: 'Действия',
        dataIndex: 'events',
        key: 'events',
      }
    ];


    const statusesAuto = [
      {
        value: 'Обучается',
      },
      {
        value: 'Окончил обучение',
      },
      {
        value: 'Подал заявку',
      },
    ];

    return (
      <div>
        <NavBar />
        <div className="pageContent">
          <div className="page_name">Слушатели</div>
          <Space size={10}>
            <Button type="primary" style={{ marginBottom: "20px" }} onClick={() => { this.setState({ client_window: true, thisNewClient: true }); this.clearNewClientData(); }}>Добавить клиента</Button>
            <Button type="primary" style={{ marginBottom: "20px" }} onClick={() => { this.setState({ loadusers: true }); }}>Загрузить клиентов из файла</Button>
            <Button type="default" style={{ marginBottom: "20px" }} onClick={() => { this.getClients(); openNotification('Успех', 'Данные обновлены', 3, 4.5); }}><RedoOutlined /></Button>
          </Space>

          <Modal title={"Загрузка клиентов"} open={this.state.loadusers} okText={"Добавить"} cancelText="Отмена" onOk={() => { this.uploadFile() }} onCancel={() => { this.setState({ loadusers: false }) }}>
            <form encType="multipart/form-data" >
              <label className="loadPlace" htmlFor="pc-upload-add" >
                <input style={{ display: "none" }} onChange={(e) => { this.setState({ loadInput: e, loadInputName: e.target.files[0].name }); console.log(e); }} type="file" id="pc-upload-add" multiple={false} />
                <span>{this.state.loadInputName}</span>
              </label>
            </form>
          </Modal>


          <Table columns={columns} dataSource={this.state.clients} />

          <Drawer
            title={this.state.thisNewClient === true ? "Новый клиент " + this.state.newClientSurname + " " + this.state.newClientName + " " + this.state.newClientPatronymic : this.state.newClientSurname + " " + this.state.newClientName + " " + this.state.newClientPatronymic}
            placement="bottom"
            height={"100vh"}
            onClose={() => { this.closeDrawer(); }}
            open={this.state.client_window}
            extra={
              <Space>
                {
                  this.state.thisNewClient === true && this.state.inputChanged === true && this.state.newClientSurname !== "" && this.state.newClientName !== "" && this.state.newClientPhone !== "" && this.state.newClientMail !== "" ?
                    <Button type="primary" onClick={() => { this.createClient(); }}>
                      Добавить клиента
                    </Button>
                    :
                    ""
                }

                {
                  this.state.thisNewClient === false && this.state.inputChanged === true ?
                    <Button type="primary" onClick={() => { this.patchClient(); }}>
                      Сохранить изменения
                    </Button>
                    :
                    ""
                }

                <Button onClick={() => { this.closeDrawer() }}>Закрыть</Button>
              </Space>
            }
          >
            {
              this.state.thisNewClient === true ?
                <Alert
                  message={"ВНИМАНИЕ! Для создания клиента и дальнейшей его идентификации необходимо заполнить поля: имя, фамилия, телефон и почта. Надеемся на понимание, это сделано для вашего же удобства."}
                  type="warning"
                  banner
                />
                : ""
            }
            <Row gutter={[16, 16]}>
              <Col flex={1}>
                <Divider orientation="left">Личные данные</Divider>
                <Space direction="vertical">
                  <Input addonBefore="Имя:" name="newClientName" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientName} />
                  <Input addonBefore="Фамилия:" name="newClientSurname" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientSurname} />
                  <Input addonBefore="Отчество:" name="newClientPatronymic" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientPatronymic} />
                  <Input addonBefore="Почта:" name="newClientMail" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientMail} placeholder="xxx@xxx.xx" />
                  <Input addonBefore="Раб. почта:" name="newClientWorkMail" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientWorkMail} placeholder="xxx@xxx.xx" />
                  <Input addonBefore="Доп. почта:" name="newClientAdditionalMail" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientAdditionalMail} placeholder="xxx@xxx.xx" />
                  <Input addonBefore="Телефон:" name="newClientPhone" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientPhone} placeholder="+7xxxxxxxxxx" />
                  <Input addonBefore="Раб. телефон:" name="newClientWorkPhone" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientWorkPhone} placeholder="+7xxxxxxxxxx" />
                  <Input addonBefore="Доп. телефон:" name="newClientAdditionalPhone" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientAdditionalPhone} placeholder="+7xxxxxxxxxx" />
                  <Input addonBefore="Фактический адрес:" name="newClientCity" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientCity} />
                  <Input addonBefore="Регион:" name="newClientRegion" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientRegion} />
                  <Input addonBefore="Адрес прописки:" name="newClientCity2" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientCity2} />
                  <Input addonBefore="День рождения:" name="newClientBirthday" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientBirthday} />
                  <Input addonBefore="Пол:" name="newClientPol" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientPol} />
                  <Input addonBefore="СНИЛС:" name="newClientSnils" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientSnils} placeholder="xxx-xxx-xxx xx" />
                </Space>
              </Col>
              <Col flex={1}>
                <Divider orientation="left">Данные об образовании</Divider>

                <Space direction="vertical">
                  <Input addonBefore="Уровень образования:" name="newClientLevelDiplom" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientLevelDiplom} />
                  <Input addonBefore="ФИО в дипломе:" name="newClientFioDiplom" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientFioDiplom} />
                  <Input addonBefore="Серия диплома:" name="newClientSeriesDiplom" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientSeriesDiplom} />
                  <Input addonBefore="Номер диплома:" name="newClientNumberDiplom" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientNumberDiplom} />
                  <Input addonBefore="Учреждение:" name="newClientInstitution" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientInstitution} />
                </Space>
              </Col>
              <Col flex={1}>
                <Divider orientation="left">Данные по обучению</Divider>
                <Space direction="vertical">
                  <Input.Group compact>
                    <span className="input_line_name">Статус:</span>
                    <AutoComplete addonBefore="Статус:" style={{ width: 'calc(100% - 65px)' }} value={this.state.newClientStatus} options={statusesAuto} filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} name="newClientStatus" onChange={(val) => { this.setState({ newClientStatus: val }) }} />
                  </Input.Group>
                  <Input addonBefore="Серия удостоверения:" value={this.state.newClientDocSeries} name="newClientDocSeries" onChange={this.ChangeInputValue.bind(this)} />
                  <Input addonBefore="Номер удостоверения:" value={this.state.newClientDocNumber} name="newClientDocNumber" onChange={this.ChangeInputValue.bind(this)} />
                  <Input addonBefore="Дата удостоверения:" value={this.state.newClientDocDate} name="newClientDocDate" onChange={this.ChangeInputValue.bind(this)} />
                  <Input addonBefore="Регистрационный номер:" value={this.state.newClientRegistrationNumber} name="newClientRegistrationNumber" onChange={this.ChangeInputValue.bind(this)} />
                  {/* <Input addonBefore="Группа:" name="newClientGroupId" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientGroupId} /> */}
                  {/* <Input addonBefore="Курс:" name="newClientCourseId" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientCourseId} /> */}
                  {/* <Input addonBefore="Проект:" name="newClientProjectId" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientProjectId} /> */}
                  {/* <Input addonBefore="Цена:" value={this.state.newClientPrice} name="newClientPrice" onChange={this.ChangeInputValue.bind(this)} /> */}
                  <Input addonBefore="Категория:" name="newClientCategory" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientCategory} />
                  {/* <Input addonBefore="Платформа обучения:" name="newClientPlatformId" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientPlatformId} /> */}

                  <Space direction="horizontal">
                    <Input addonBefore="Профиль в платформе фед. оператора:" name="newClientFedLink" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientFedLink} />
                    <Button
                      style={{
                        width: 80,
                      }}
                      onClick={() => { window.open(this.state.newClientFedLink, '_blank').focus(); }}
                    >
                      <EyeOutlined />
                    </Button>
                  </Space>
                  {/* <Input addonBefore="Прикрепленный менеджер:" name="newClientManager" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientManager} /> */}
                  <Input addonBefore="Номер приказа на зачисление:" name="newClientNumberTransferOrder" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientNumberTransferOrder} />
                  <Input addonBefore="Дата регистрации приказа на зачисление:" name="newClientDateTransferOrder" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientDateTransferOrder} />
                  <Input addonBefore="Номер приказа на отчисление:" name="newClientNumberWithdrawalOrder" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientNumberWithdrawalOrder} />
                  <Input addonBefore="Дата регистрации приказа на отчисление:" name="newClientDateWithdrawalOrder" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientDateWithdrawalOrder} />
                </Space>
              </Col>
            </Row>
            <Modal title={"Изменение пароля клиента"} open={this.state.clientPassEdit} okText={"Изменить"} cancelText="Отмена" onOk={() => { this.editClientPassword() }} onCancel={() => { this.setState({ clientPassEdit: false }) }}>
              <Input addonBefore="Новый пароль:" value={this.state.newClientPassword} name="newClientPassword" onChange={(e) => { this.setState({ newClientPassword: e.target.value }) }} />
            </Modal>
            <Row gutter={[16, 16]}>
              <Col flex={1}>
                <Divider orientation="left">Данные авторизации</Divider>
                {
                  this.state.thisNewClient === true ?
                    <Input addonBefore="Пароль:" value={this.state.newClientPassword} name="newClientPassword" onChange={(e) => { this.setState({ newClientPassword: e.target.value }) }} />
                    :
                    <Space direction="vertical">
                      <Input addonBefore="Логин:" value={this.state.newClientLogin} name="newClientLogin" onChange={this.ChangeInputValue.bind(this)} />
                      <Button type="primary" onClick={() => { this.setState({ clientPassEdit: true }) }}>Изменить пароль</Button>
                    </Space>
                }
              </Col>
            </Row>

          </Drawer>
        </div>
      </div>
    );


  }

  async componentDidMount() {
    // this.getUsers();
    // this.getGroups();
    // this.getCourses();
    // this.getPlatforms();
    // this.getStatuses();
    this.getClients();
    // this.getProjects();
  }

}